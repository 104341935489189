// Global Helper File
import React, { useEffect, useState } from "react";
export function convertKMBT (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export function sortDateDesc(arr){
    return arr.sort((firstItem, secondItem) =>  new Date(secondItem.lastUpdate) - new Date(firstItem.lastUpdate));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
 }

 
  
 