export const LayoutConfig = [
    {
        id: 'layout_1',
        author: true,
        authorColor: '#000',
        authorBg: '#8BD4FF',
        title: 'top',
        titleColor: 'white',
        titleBg: '#22508C'
    },
    {
        id: 'layout_2',
        author: true,
        authorColor: '#000',
        authorBg: '#8BD4FF',
        title: 'bottom',
        titleColor: 'white',
        titleBg: '#22508C'
    },
    {
        id: 'layout_3',
        author: false,
        authorColor: '#000',
        authorBg: '#fff',
        title: 'top',
        titleColor: '#000',
        titleBg: '#fff'
    },
    {
        id: 'layout_4',
        author: true,
        authorColor: '#000',
        authorBg: '#A489FA',
        title: 'top',
        titleColor: 'white',
        titleBg: '#39296A'
    },
    {
        id: 'layout_5',
        author: true,
        authorColor: '#000',
        authorBg: '#A489FA',
        title: 'top',
        titleColor: 'white',
        titleBg: '#39296A'
    }
];
